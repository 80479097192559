<template>
  <div class="wrapper">
    <div id="stripe-wrapper">
      <div id="stripe" class="magenta"/>
      <div id="stripe" class="orange"/>
      <div id="stripe" class="yellow"/>
      <div id="stripe" class="green"/>
      <div id="stripe" class="blue"/>
    </div>
    <div id="body">
      <h1>Evan Miller</h1>
      <h3>Developer | Mentor | Climber</h3>
      <div id="image-wrapper">
        <a href="https://www.linkedin.com/in/evansays/">
          <img alt="Linkedin logo" src="../assets/linkedin-evansays.svg">
        </a>
        <a href="https://github.com/EvanSays">
          <img id="image2" alt="Github logo" src="../assets/github-evansays.svg">
        </a>
      </div>
    </div>
    <div id="footer">@evansays • up late with 🧉</div>
  </div>
</template>

<script>
export default {
  name: 'Main'
}
</script>

<style scoped>

.wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  background-color: #FFFEF9;
}

#stripe-wrapper{
  position: absolute;
  left: 100px;
  display: flex;
}
#stripe {
  height: 100vh;
  width: 50px;
}

.magenta {
  background-color: #E7288D;
}
.orange {
  background-color: #F18046;
}
.yellow {
  background-color: #FDDC3A;
}
.green {
  background-color: #B7C53E;
}
.blue {
  background-color: #25BCDC;
}
/* .magenta {
  background-color: #F6CCAB;
}
.orange {
  background-color: #EC7B80;
}
.yellow {
  background-color: #BB707D;
}
.green {
  background-color: #6A5C71;
}
.blue {
  background-color: #3F5B6F;
} */

#body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
h1 {
  font-size: 96px;
  font-weight: bold;
  text-align: left;
  margin: 0;
}
h3 {
  font-size: 36px;
  font-weight: 300;
  text-align: left;
  margin: 0;
}

#image-wrapper{
  display: flex;
  margin-top: 25px;
}

img {
  height: 35px;
  width: 35px;
}
#image2{
  margin-left: 25px;
}
#footer{
  font-size: 18px;
  font-weight:400;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
}

@media (max-width: 1350px) { 
  #stripe-wrapper{
    left: 0px;
  }
}

@media (max-width: 1150px) { 
  #stripe-wrapper{
    left: 50%;
    transform: translate(-50%);
  }
  #stripe {
    height: 20vh;
    width: 50px;
  }
  h1 {
    font-size: 64px;
    text-align: center;
  }
  h3 {
    font-size: 24px;
    text-align: center;
  }
  #image-wrapper{
    justify-content: center;
  }
  img {
    height: 30px;
    width: 30px;
  }
  #image2{
    margin-left: 20px;
  }
  #footer{
  font-size: 14px;
  }
}

@media (max-width: 400px) { 
  h1 {
    font-size: 48px;
  }
  h3 {
    font-size: 24px;
  }
}
</style>